import Swiper from 'swiper';
import { Autoplay, Scrollbar } from 'swiper/modules';

var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
// make Isotope a jQuery plugin
jQueryBridget( 'isotope', Isotope, $ );


window.addEventListener('DOMContentLoaded', function() {

    //Logo slider
    if ( document.querySelector('.logo_slider ._slider') ) {
        const swiperSlider = new Swiper('.logo_slider ._slider', {
            modules: [Autoplay],
            slidesPerView: 'auto',
            spaceBetween: 46,
            loop: true,
            speed: 4000,
			autoplay: {
				delay: 0,
                reverseDirection: true,
			},
            breakpoints: {
                768: {
                  spaceBetween: 111
                }
            }
        });
    }

    //Projects slider
    if ( document.querySelector('.projects_slider ._slider') ) {
        const swiperSlider = new Swiper('.projects_slider ._slider', {
            modules: [Scrollbar],
            slidesPerView: 'auto',
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
        });
    }

    //Timeline slider
    if ( document.querySelector('.timeline_slider ._slider') ) {
        const swiperSlider = new Swiper('.timeline_slider ._slider', {
            modules: [Scrollbar],
            slidesPerView: 'auto',
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
        });
    }


    //Filter block
    if ( document.querySelector('.filter_block') ) {
        const swiperSlider = new Swiper('.filter_block ._slider', {
            slidesPerView: 'auto',
            spaceBetween: 20
        });

        let filter_items = document.querySelectorAll('.filter_block ._item')
        for ( let item of filter_items ) {
            item.addEventListener('click',  function() {
                let filter_block = this.closest('.filter_block')
                let filter_block_items = filter_block.querySelectorAll('._item')
                for ( let filter_block_item of filter_block_items ) {
                    filter_block_item.classList.remove('active')
                }
                this.classList.add('active')
            })  
        }
    }


    //Isotope
    if ( document.querySelector('._isotope') ) {
        var $grid = $('._isotope ._grid').isotope({
            itemSelector: '._grid_item',
            layoutMode: 'fitRows',
            masonry: {
                // set to the element
                columnWidth: '._grid_item'
            }
        });

        let filter = document.querySelector('._isotope .filter_block')
        filter.addEventListener('click',  function(e) {
            //console.log('e.target', e.target)
            if ( e.target.getAttribute('data-filter') ) {
                let filterValue = e.target.getAttribute('data-filter')
                //console.log('e.target filterValue', filterValue)
                $grid.isotope({ filter: filterValue })
            }
        })
    }


    //Navigation
    if ( document.querySelector('._navigation') ) {
        let filter = document.querySelector('._navigation .filter_block')
        filter.addEventListener('click',  function(e) {
            if ( e.target.getAttribute('data-anchor') ) {
                let anchor = e.target.getAttribute('data-anchor')
                document.querySelector(`[id="${anchor}"]`).scrollIntoView()
            }
        })
    }


    //Accordion
    if ( document.querySelector('.accordion_block__header') ) {
        let accordion_headers = document.querySelectorAll('.accordion_block__header')
        for( let item of accordion_headers ){
            item.addEventListener('click',  function() {

                let accordion_block = this.closest('.accordion_block')
                if( accordion_block.classList.contains('active') ){
                    return
                }
                
                if ( accordion_block.getAttribute('data-region') ) {
                    let region = accordion_block.getAttribute('data-region')
                    let global_reach = this.closest('.global_reach')
                    
                    let accordion_blocks = global_reach.querySelectorAll(`.accordion_block`)
                    for ( let item of accordion_blocks ) {
                        item.classList.remove('active')
                    }
                    accordion_block.classList.add('active')
                    
                    let locations = global_reach.querySelectorAll(`._location[data-region]`)
                    for ( let location of locations ) {
                        if ( region === location.getAttribute('data-region') ) {
                            location.classList.add('active')
                        } else {
                            location.classList.remove('active')
                        }
                    }
                } else {
                    accordion_block.classList.toggle('active')
                }
                
            })
        }
    }


    const bp_768 = window.matchMedia( '(max-width:768px)' );
    const bpChecker_768 = function() {
    
      if ( bp_768.matches === true ) {
    
        if ( document.querySelector('.hero img') ) {
            let hero_img_all = document.querySelectorAll('.hero img')
            for ( let hero_img of hero_img_all ) {
                let src = hero_img.getAttribute('data-mob')
                hero_img.src = src
            }
        }
        
    
      } else if ( bp_768.matches === false ) {
    
        if ( document.querySelector('.hero img') ) {
            let hero_img_all = document.querySelectorAll('.hero img')
            for ( let hero_img of hero_img_all ) {
                let src = hero_img.getAttribute('data-desk')
                hero_img.src = src
            }
        }
    
        
      }
    
    };
    bp_768.addListener( bpChecker_768 )
    bpChecker_768();

})